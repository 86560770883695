import React, { ReactNode } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => {
  return {
    loaderBackground: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1000,
      backgroundColor: 'white',
      opacity: 0.35,
      pointerEvents: 'none',
    },
    loaderWrapper: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1000,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    loaderWrapperWithMessage: {
      position: 'fixed',
      top: -150,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1000,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    message: {
      position: 'fixed',
      top: 450,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      '& div': {
        fontSize: theme.typography.pxToRem(20),
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.grey[500],
        maxWidth: theme.spacing(130),
        minHeight: theme.spacing(50),
        textAlign: 'center',
      },
    },
    loaderImg: {
      width: '400px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }
})

interface CircleLoaderProps {
  message?: ReactNode
}

const CircleLoader: React.FC<CircleLoaderProps> = ({ message }) => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.loaderBackground}></div>
      <div
        className={
          message ? classes.loaderWrapperWithMessage : classes.loaderWrapper
        }
      >
        <img className={classes.loaderImg} alt="" src="/static/loading.gif" />
        {message && <div className={classes.message}>{message}</div>}
      </div>
    </>
  )
}

export default CircleLoader
