import React from 'react'
import { FieldProps, getIn } from 'formik'

import LiquidCheckbox from '@src/components/LiquidCheckbox'

type LiquidCheckboxAdapterProps = FieldProps & {
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
}

const LiquidCheckboxAdapter: React.FC<LiquidCheckboxAdapterProps> = ({
  field,
  form,
  ...otherProps
}) => {
  const error = getIn(form.errors, field.name)
  const touched = getIn(form.touched, field.name)
  const hasError = error && touched

  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    field.onChange(e)
    if (otherProps.onChange) {
      otherProps.onChange(e)
    }
  }

  const handleOnBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    field.onBlur(e)
    if (otherProps.onBlur) {
      otherProps.onBlur(e)
    }
  }

  return (
    <LiquidCheckbox
      {...field}
      value={String(field.value)}
      checked={field.value}
      error={hasError ? error : null}
      {...otherProps}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
    />
  )
}

export default LiquidCheckboxAdapter
