import Icon, { BaseIconProps } from './Icon'
function CheckMarkIcon({ ...props }: BaseIconProps) {
  return (
    <Icon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 14" {...props}>
        <path d="M7.318 14a1.56 1.56 0 0 1-1.132-.488L.447 7.482A1.644 1.644 0 0 1 .477 5.2a1.56 1.56 0 0 1 2.234.03l4.651 4.887L17.335.445a1.55 1.55 0 0 1 2.232.061 1.64 1.64 0 0 1-.06 2.282L8.4 13.556A1.55 1.55 0 0 1 7.318 14" />
      </svg>
    </Icon>
  )
}
export default CheckMarkIcon
