import Icon, { BaseIconProps } from './Icon'
function DownloadIcon({ ...props }: BaseIconProps) {
  return (
    <Icon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 19" {...props}>
        <path d="M19.3 17.05H.7a.7.7 0 0 0 0 1.4h18.6a.7.7 0 0 0 0-1.4m-9.79-3.46a.54.54 0 0 0 .22.15.64.64 0 0 0 .54 0 .54.54 0 0 0 .22-.15l4.65-4.65a.69.69 0 1 0-1-1l-3.44 3.48V.7a.7.7 0 1 0-1.4 0v10.72L5.84 8a.69.69 0 1 0-1 1z" />
      </svg>
    </Icon>
  )
}
export default DownloadIcon
