import { defineMessages } from 'react-intl'

export interface Message {
  id: string
  defaultMessage: string
}

export const messages = defineMessages({
  ESTIMATED_TO_SHIP_ON: {
    id: 'ESTIMATED_TO_SHIP_ON',
    defaultMessage: 'Estimated to ship on',
  },
  AVAILABLE_TO_SHIP_ON: {
    id: 'AVAILABLE_TO_SHIP_ON',
    defaultMessage: 'Available to ship on',
  },
  FEW_LEFT_IN_STOCK: {
    id: 'FEW_LEFT_IN_STOCK',
    defaultMessage: '{qty} left in stock (more on the way)',
  },
  LIMITED_AVAILABILITY: {
    id: 'LIMITED_AVAILABILITY',
    defaultMessage: 'Limited Availability',
  },
  CONTACT_FOR_AVAILABILITY: {
    id: 'CONTACT_FOR_AVAILABILITY',
    defaultMessage: 'Please contact Customer Service for Availability',
  },
  CONTACT_LOCAL_CUSTOMER_SERVICE_FOR_DELIVERY_ESTIMATE: {
    id: 'CONTACT_LOCAL_CUSTOMER_SERVICE_FOR_DELIVERY_ESTIMATE',
    defaultMessage:
      'Product Availability: Please contact your  {link} for delivery estimate.',
  },
  APO_NO_STOCK: { id: 'APO_NO_STOCK', defaultMessage: 'No Local Stock' },
  FULFILMENT_DELIVERY_DELAYED: {
    id: 'FULFILMENT_DELIVERY_DELAYED',
    defaultMessage: 'Fulfilment and delivery delayed',
  },
  ESTIMATED_TO_SHIP_ON_DYNAMIC: {
    id: 'ESTIMATED_TO_SHIP_ON_DYNAMIC',
    defaultMessage: '{quantity}Estimated to ship on {date} from {plantLoc}',
  },
  AVAILABLE_TO_SHIP_ON_DYNAMIC: {
    id: 'AVAILABLE_TO_SHIP_ON_DYNAMIC',
    defaultMessage: '{quantity}Available to ship on {date} from {plantLoc}',
  },
  ESTIMATED_TO_SHIP_ON_DYNAMIC_WITHOUT_PLANT_LOC: {
    id: 'ESTIMATED_TO_SHIP_ON_DYNAMIC_WITHOUT_PLANT_LOC',
    defaultMessage: '{quantity}Estimated to ship on {date}',
  },
  AVAILABLE_TO_SHIP_ON_DYNAMIC_WITHOUT_PLANT_LOC: {
    id: 'AVAILABLE_TO_SHIP_ON_DYNAMIC_WITHOUT_PLANT_LOC',
    defaultMessage: '{quantity}Available to ship on {date}',
  },
  ESTIMATED_DELIVERY_ON_DYNAMIC: {
    id: 'ESTIMATED_DELIVERY_ON_DYNAMIC:',
    defaultMessage: '{quantity}Estimated Delivery on {date} from {plantLoc}',
  },
  ESTIMATED_DELIVERY_ON: {
    id: 'ESTIMATED_DELIVERY_ON',
    defaultMessage: '{quantity}Estimated Delivery on {date}',
  },
  SHIPPING_DATE_NOT_AVAILABLE: {
    id: 'SHIPPING_DATE_NOT_AVAILABLE',
    defaultMessage: '{quantity}Shipping Date not available',
  },
  PRODUCT_AVAILABILITY_LIMITED_AVAILABILITY: {
    id: 'PRODUCT_AVAILABILITY_LIMITED_AVAILABILITY',
    defaultMessage: 'Product Availability: Limited Availability',
  },
  AVAILABILITY_UNKNOWN: {
    id: 'AVAILABILITY_UNKNOWN',
    defaultMessage: 'Availability Unknown',
  },
})

export default messages
