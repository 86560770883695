import React from 'react'
import { useIntl } from 'react-intl'
import { makeStyles, Theme } from '@material-ui/core/styles'
import messages from '@src/utils/messages'
import DownloadIcon from '@src/icons/DownloadIcon'
import clsx from 'clsx'

const styles = makeStyles((theme: Theme) => {
  return {
    sdsRoot: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.main,
      '&:hover': {
        '& > svg, span': {
          color: theme.palette.primary.dark,
        },
      },
    },
    message: {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      marginLeft: theme.spacing(2),
      fontSize: '14px',
    },
    hideBelowSm: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  }
})

interface LabeledDownloadIconProps {
  label?: string
  alwaysShowLabel?: boolean
}

const LabeledDownloadIcon: React.FC<LabeledDownloadIconProps> = ({
  label = 'SDS',
  alwaysShowLabel,
}) => {
  const classes = styles()
  const { formatMessage } = useIntl()

  return (
    <span className={classes.sdsRoot}>
      <DownloadIcon color="primary" />
      <span
        className={clsx(classes.message, {
          [classes.hideBelowSm]: !alwaysShowLabel,
        })}
      >
        {formatMessage(messages[label])}
      </span>
    </span>
  )
}

export default LabeledDownloadIcon
