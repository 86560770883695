import { useState, useEffect, ComponentType } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'
import { GridSpacing } from '@material-ui/core'
import { find as _find, isNil as _isNil } from 'lodash'

export enum Breakpoints {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

// Explicitly declare order large to small - necessary to ensure the correct size is applied
// as the largest matched breakpoint's size should be used.
const BREAKPOINT_ORDER = [
  Breakpoints.XL,
  Breakpoints.LG,
  Breakpoints.MD,
  Breakpoints.SM,
  Breakpoints.XS,
]

interface ResponsiveValueMap<T> {
  [Breakpoints.XS]?: T
  [Breakpoints.SM]?: T
  [Breakpoints.MD]?: T
  [Breakpoints.LG]?: T
  [Breakpoints.XL]?: T
}

interface BreakpointMatches {
  [Breakpoints.XS]: boolean
  [Breakpoints.SM]: boolean
  [Breakpoints.MD]: boolean
  [Breakpoints.LG]: boolean
  [Breakpoints.XL]: boolean
}

const useBreakpointMatches = () => {
  const theme = useTheme()

  return {
    [Breakpoints.XS]: useMediaQuery(theme.breakpoints.up(Breakpoints.XS)),
    [Breakpoints.SM]: useMediaQuery(theme.breakpoints.up(Breakpoints.SM)),
    [Breakpoints.MD]: useMediaQuery(theme.breakpoints.up(Breakpoints.MD)),
    [Breakpoints.LG]: useMediaQuery(theme.breakpoints.up(Breakpoints.LG)),
    [Breakpoints.XL]: useMediaQuery(theme.breakpoints.up(Breakpoints.XL)),
  }
}

const getMatchedKey = <T>(
  sizes: ResponsiveValueMap<T>,
  matches: BreakpointMatches
): Breakpoints => {
  return (
    _find(BREAKPOINT_ORDER, (key) => !_isNil(sizes[key]) && matches[key]) ||
    Breakpoints.XS
  )
}

const useResponsiveSizes = () => {
  const matches = useBreakpointMatches()

  return (sizes: ResponsiveValueMap<number>): number => {
    const sizeKey = getMatchedKey(sizes, matches)
    return sizes[sizeKey] || 0
  }
}

export const useResponsiveGridSpacing = () => {
  const matches = useBreakpointMatches()

  return (sizes: ResponsiveValueMap<GridSpacing>): GridSpacing => {
    const sizeKey = getMatchedKey(sizes, matches)
    return sizes[sizeKey] || 0
  }
}

// Use this hook to selectively render different components
// based on the current breakpoints. These components should
// have the same props signature.
// Use the default to determine which component is rendered during SSR
export const useResponsiveComponent = <P>(
  keyMap: ResponsiveValueMap<string>,
  componentMap: Record<string, ComponentType<P>>,
  defaultKey: string
) => {
  const [key, setKey] = useState<string | undefined>(defaultKey)
  const matches = useBreakpointMatches()
  const matchedKey = getMatchedKey(keyMap, matches)

  useEffect(() => {
    setKey(keyMap[matchedKey])
  }, [matchedKey])

  const componentKey = key || defaultKey
  return componentMap[componentKey]
}

export const useResponsiveKey = <K>(
  keyMap: ResponsiveValueMap<K>,
  defaultKey: K
) => {
  const [key, setKey] = useState<K | undefined>(defaultKey)
  const matches = useBreakpointMatches()
  const matchedKey = getMatchedKey(keyMap, matches)

  useEffect(() => {
    setKey(keyMap[matchedKey])
  }, [matchedKey])

  return key || defaultKey
}

export default useResponsiveSizes
