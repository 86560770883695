import React, { useState } from 'react'
import { Link as MUILink, Theme } from '@material-ui/core'
import { useIntl } from 'react-intl'
import mainMessages from '@src/utils/messages'
import DownloadIcon from '@src/icons/DownloadIcon'
import { sdsRoute } from '@src/routes'
import SDSModal from '@src/components/SDSModal'
import { EventActions } from '@utils/analytics/types'
import { sendDownloadFileEvent } from '@src/utils/analytics'
import { useUserSession } from '@utils/useUserSession'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useCurrentUser } from '@utils/useCurrentUser'

export const languages = {
  en_US: 'English - EN',
  en_GENERIC: 'English - EN',
  zh_CN: '中文 - ZH',
}

export const useStyles = makeStyles((theme: Theme) => ({
  triggerLink: {
    fontSize: '14px',
    fontFamily: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  noCertificate: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.grey[400],
  },
  message: {
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: theme.spacing(3),
    fontSize: '14px',
  },
}))

interface SDSLinkProps {
  sdsLanguages: Maybe<string>[]
  productNumber: string
  brandKey: string
  trigger?: React.ReactNode
  handleClick?: (value: any) => void
  color?: 'primary' | 'secondary' | undefined
  className?: string
  onClick?: (value?: any) => void
}

const SDSLink: React.FC<SDSLinkProps> = ({
  sdsLanguages = [],
  productNumber,
  brandKey,
  trigger = <DownloadIcon color="primary" />,
  color = 'secondary',
  className,
  onClick,
  handleClick,
}) => {
  const [sdsModalOpen, setSdsModalOpen] = useState(false)
  const { userSession } = useUserSession()
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const { userErpType } = useCurrentUser()
  return (
    <>
      {sdsLanguages.length === 1 && !!sdsLanguages[0] && (
        <MUILink
          href={sdsRoute.index(
            brandKey,
            productNumber,
            userSession.country,
            sdsLanguages[0].split('_')[0],
            userErpType
          )}
          data-testid={`sds-${brandKey}${productNumber}`}
          id={`sds-${brandKey}${productNumber}`}
          target="_blank"
          rel="noopener noreferrer nofollow"
          color={color}
          tabIndex={0}
          onClick={() => {
            if (onClick) onClick()
            sendDownloadFileEvent(
              {
                action: EventActions.SDS,
                brandKey,
                productNumber,
                language: String(sdsLanguages[0]),
              },
              {
                component: '',
                elementType: '',
                linkText: '',
                linkUrl: sdsRoute.index(
                  brandKey,
                  productNumber,
                  userSession.country,
                  sdsLanguages[0]?.split('_')[0] || '',
                  userErpType
                ),
              }
            )
          }}
        >
          {trigger}
        </MUILink>
      )}

      {sdsLanguages.length === 0 && (
        <span
          className={classes.noCertificate}
          onMouseEnter={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            handleClick?.(e)
          }}
        >
          <DownloadIcon color="inherit" />
          <span className={classes.message}>
            {formatMessage(mainMessages.SDS)}
          </span>
        </span>
      )}

      {sdsLanguages.length > 1 && (
        <>
          <MUILink
            component="button"
            data-testid={`sds-${brandKey}${productNumber}`}
            id={`sds-${brandKey}${productNumber}`}
            aria-describedby="sds"
            onClick={(e) => {
              e.preventDefault()
              if (onClick) onClick()
              setSdsModalOpen(true)
            }}
            color={color}
            className={clsx(className, classes.triggerLink)}
            tabIndex={0}
          >
            {trigger}
          </MUILink>
          <SDSModal
            open={sdsModalOpen}
            setModalOpen={setSdsModalOpen}
            brands={[brandKey]}
            productNumber={productNumber}
            sdsLanguages={sdsLanguages as string[]}
          />
        </>
      )}
    </>
  )
}

export default SDSLink
