import React, { ReactNode, CSSProperties } from 'react'
import clsx from 'clsx'
import { FieldProps, getIn } from 'formik'

import LiquidInput from '../LiquidInput'
import { LiquidInputProps } from '../LiquidInput/LiquidInput'
import LiquidFormLabel from '../LiquidFormLabel'
import LiquidFormHelpText from '../LiquidFormHelpText'

interface LiquidInputAdapterProps extends FieldProps, LiquidInputProps {
  rootClass?: string
  rootStyle?: CSSProperties
  label?: ReactNode
  required?: boolean
  help?: ReactNode
  helpStyle?: CSSProperties
  displayError?: boolean
  toolTipText?: ReactNode
  hasErrorStyle?: boolean
}

const LiquidInputAdapter: React.FC<LiquidInputAdapterProps> = ({
  rootClass,
  rootStyle,
  size = 'medium',
  field,
  form,
  label,
  required,
  help,
  style,
  helpStyle,
  displayError = true,
  toolTipText,
  hasErrorStyle = true,
  ...otherProps
}) => {
  const error = getIn(form.errors, field.name)
  const touched = getIn(form.touched, field.name)
  const hasError = error && touched && hasErrorStyle
  const message = hasError ? error : help || null

  return (
    <div
      className={clsx({
        [String(rootClass)]: rootClass,
      })}
      style={rootStyle}
    >
      {label && (
        <LiquidFormLabel
          htmlFor={field.name}
          required={required}
          size={size}
          error={Boolean(hasError) || otherProps?.error}
          style={style}
          toolTipText={toolTipText}
        >
          {label}
        </LiquidFormLabel>
      )}
      <LiquidInput
        id={field.name}
        {...field}
        size={size}
        error={Boolean(hasError)}
        aria-describedby={(hasError || help) && `${field.name}-help-text`}
        {...otherProps}
      />
      {(hasError || help) && displayError && (
        <LiquidFormHelpText
          id={`${field.name}-help-text`}
          error={Boolean(hasError)}
          style={helpStyle}
        >
          {message}
        </LiquidFormHelpText>
      )}
    </div>
  )
}

export default LiquidInputAdapter
