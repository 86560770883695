import React, { useState } from 'react'
import getConfig from 'next/config'
import { useIntl } from 'react-intl'
import {
  Theme,
  FormControlLabel,
  RadioGroup,
  Grid,
  Link as MuiLink,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { sdsRoute } from '@src/routes'
import { SdsData } from '@src/types/graphql-types'
import messages from '@utils/messages'
import RadioButton from '@src/components/RadioButton'
import { EventActions } from '@utils/analytics/types'
import { sendDownloadFileEvent } from '@src/utils/analytics'
import { useUserSession } from '@utils/useUserSession'
import ResponsiveModal from '@src/components/ResponsiveModal'
import { useCurrentUser } from '@utils/useCurrentUser'

const useStyles = makeStyles((theme: Theme) => {
  return {
    dialogHeader: {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: theme.typography.fontWeightBold,
    },
    modalBody: {
      padding: theme.spacing(0, 4, 4, 4),
      overflow: 'auto',
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0, 8, 8, 8),
      },
    },
    modalTitle: {
      marginBottom: theme.spacing(2),
    },
    modalProductNumber: {
      fontSize: theme.typography.pxToRem(18),
    },
    brandSelectMessage: {
      display: 'block',
      fontSize: theme.typography.pxToRem(18),
      marginBottom: theme.spacing(4),
    },
    brandRadioList: {
      paddingTop: theme.spacing(4),
      borderTop: '1px solid ' + theme.palette.divider,
      marginTop: theme.spacing(4),
    },
    brandLabel: {
      textTransform: 'capitalize',
    },
    languageList: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexFlow: 'column',
      },
      paddingTop: theme.spacing(4),
      borderTop: '1px solid ' + theme.palette.divider,
      marginTop: theme.spacing(4),
    },
    languageLink: {
      display: 'block',
      fontWeight: theme.typography.fontWeightBold,
      marginBottom: theme.spacing(4),
    },
  }
})

const {
  publicRuntimeConfig: { supportedLanguageLabels },
} = getConfig()

interface SDSModalProps {
  open: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  brands: string[]
  sdsDetails?: SdsData[]
  productNumber: string
  isAdvancedSearch?: boolean
  sdsLanguages?: string[]
}

interface SDSLanguages {
  formatted: string
  abbrev: string
}

// will address language translation preferences (user lang vs. displaying language in that language), alphabetiztion in STRAT-9645
const getLanguageCodes = (sdsLanguages: string[], formatDisplayName) =>
  sdsLanguages
    .map((lang) => {
      // formatjs ie11 polyfill won't match an uppercase language code
      // even though native Intl.Locale will. This code doesn't account
      // for localized language codes (e.g. en-US). Products with localized
      // translations may need to be accounted for in a more robust manner

      // using supported translated language labels like the language selection dropdown in the header
      // keeping the original label display method as a fallback incase sds language isn't in the list
      const localeLang = lang?.toLowerCase() === 'br' ? 'pt' : lang
      const formattedLanguageText =
        supportedLanguageLabels[localeLang.toUpperCase()] ||
        `${formatDisplayName(localeLang, {
          type: 'language',
          localeMatcher: 'best fit',
        })} - ${lang}`

      return {
        formatted: formattedLanguageText,
        abbrev: lang,
      }
    })
    .filter((sdsLang) => !sdsLang.formatted.includes('undefined'))

const getLangAbbreviation = (language: string) => {
  const dash = language.indexOf('-')
  return language.slice(dash + 1).trim()
}

const SDSModal: React.FC<SDSModalProps> = ({
  open,
  setModalOpen,
  brands,
  sdsDetails,
  productNumber,
  isAdvancedSearch,
  sdsLanguages,
}) => {
  const classes = useStyles()
  const { formatMessage, formatDisplayName } = useIntl()
  const { userSession } = useUserSession()
  const { userErpType } = useCurrentUser()
  const [selectedBrand, setSelectedBrand] = useState('')
  const isSingleBrand = brands.length === 1
  const singleBrandName = brands[0]

  let languages: Maybe<SDSLanguages[]> = []

  if (sdsLanguages) {
    languages = getLanguageCodes(sdsLanguages, formatDisplayName)
  } else if (selectedBrand && sdsDetails) {
    const selectedSds = sdsDetails.find((sds) => sds.brand === selectedBrand)
    if (selectedSds) {
      languages = getLanguageCodes(
        selectedSds.languages.altLanguages,
        formatDisplayName
      )
    }
  } else if (isAdvancedSearch && sdsDetails) {
    languages = getLanguageCodes(
      sdsDetails[0].languages.altLanguages,
      formatDisplayName
    )
  } else return null

  return (
    <ResponsiveModal
      open={open}
      data-testid="sds-modal"
      id="sds-modal"
      onClose={() => setModalOpen(false)}
      closeButtonId="sds-modal-close-button"
      renderTitle={() => (
        <div className={classes.dialogHeader}>
          <div id="sds-modal-title" className={classes.modalTitle}>
            {formatMessage(messages.SAFETY_DATA_SHEET_TITLE)}
          </div>
          <div
            className={classes.modalProductNumber}
            id="sds-modal-product-number"
          >
            {productNumber}
          </div>
        </div>
      )}
    >
      <div className={classes.modalBody}>
        {!isSingleBrand && (
          <form aria-label="brand input" className={classes.brandRadioList}>
            <span className={classes.brandSelectMessage}>
              {' '}
              {formatMessage(messages.SDS_SELECT_BRAND)}
            </span>

            <RadioGroup
              aria-label="brand-selection"
              onChange={(e) => {
                setSelectedBrand(e.target.value)
              }}
              value={selectedBrand}
            >
              {brands.map((brand) => (
                <FormControlLabel
                  key={brand}
                  aria-label="brand"
                  value={brand}
                  control={<RadioButton />}
                  className={classes.brandLabel}
                  label={brand.toLowerCase()}
                />
              ))}
            </RadioGroup>
          </form>
        )}

        {(selectedBrand || isSingleBrand) && (
          <Grid className={classes.languageList} container>
            {languages.map((lang) => (
              <Grid item xs={12} sm={4} key={lang.formatted}>
                <MuiLink
                  className={classes.languageLink}
                  href={sdsRoute.index(
                    selectedBrand || singleBrandName,
                    productNumber,
                    userSession.country,
                    getLangAbbreviation(lang.abbrev),
                    userErpType
                  )}
                  data-testid={`sds-link-${lang.abbrev}`}
                  id={`sds-link-${lang.abbrev}`}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  color="primary"
                  onClick={() =>
                    sendDownloadFileEvent(
                      {
                        action: EventActions.SDS,
                        brandKey: selectedBrand || singleBrandName,
                        productNumber,
                        language: lang.abbrev,
                      },
                      {
                        productId: productNumber,
                        component: 'modal',
                        elementType: 'link',
                        linkText: lang.formatted,
                        linkUrl: sdsRoute.index(
                          selectedBrand || singleBrandName,
                          productNumber,
                          userSession.country,
                          getLangAbbreviation(lang.abbrev),
                          userErpType
                        ),
                      }
                    )
                  }
                >
                  {lang.formatted}
                </MuiLink>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </ResponsiveModal>
  )
}

export default SDSModal
