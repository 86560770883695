import * as yup from 'yup'
import { useIntl } from 'react-intl'
import messages from '@utils/messages'

// value: any is necessary because that's what the validateSync function takes
// and we want this to match as closely as possible
export const validateField =
  <T>(validator: yup.Schema<T>) =>
  (value: any, options?: yup.ValidateOptions): string | undefined => {
    try {
      validator.validateSync(value, options)
      return undefined
    } catch (error: any) {
      return error.message
    }
  }

export const composeValidators =
  (...validators: ReturnType<typeof validateField>[]) =>
  (value: any, options?: yup.ValidateOptions) =>
    validators.reduce(
      (error, validator) => error || validator(value, options),
      undefined as string | undefined
    )

// Check if text has any emojis or special characters. If nothing gets passed it has no emojis.
export const hasEmojis = (input: string | null | undefined): boolean => {
  if (!input) {
    return false
  }

  const emojiRegex = new RegExp('\\p{Extended_Pictographic}', 'u')

  return emojiRegex.test(input)
}

// Regex checking if email address if valid.
export const isValidEmail = (input?: string): boolean => {
  if (!input) {
    return true
  }

  const emailRegex = new RegExp(
    "(^[a-zA-Z0-9]+[a-zA-Z0-9-_.']*@(?:[a-zA-Z0-9-]*[a-zA-Z0-9]+\\.)+[a-zA-Z]{1,}$)"
  )

  return emailRegex.test(input)
}

// Regex for checking if an input is a valid phone number while allowing spaces.
export const isValidPhoneWithSpaces = (input?: string): boolean => {
  if (!input) {
    return true
  }

  const phoneSpacesRegex = new RegExp('^[0-9-()+\\- ]*$')

  // Valid phone numbers need at least 4 characters if you include 4 digit SMS/texting shortcodes.
  // Some outlying regions have as few as 5 digits: https://stackoverflow.com/questions/14894899/what-is-the-minimum-length-of-a-valid-international-phone-number
  // This may be better handled by a library itself like: https://www.npmjs.com/package/libphonenumber-js
  // Which is based on: https://github.com/google/libphonenumber
  if (input.trim().length < 4) {
    return false
  }

  return phoneSpacesRegex.test(input)
}

export const isValidChinaMobile = (
  input: string | null | undefined,
  allowEmpty = false
): boolean => {
  // If empty strings are allowed and no input is given always return true.
  if (allowEmpty && !input) {
    return true
  }

  const chinaMobileRegex = new RegExp('^1[3456789]\\d{9}$')

  return chinaMobileRegex.test(input || '')
}

/**
 * For FCs, this can be used as a hook to get validators with pre-translated
 * messages.
 *
 * For class components, you'll need to wrap the component in injectIntl, and
 * pass that injected intl prop to this function, since hooks can't be used in
 * class components (and thus the useIntl hook won't work here).
 *
 */
export const useValidators = () => {
  const intl = useIntl()

  const email = yup
    .string()
    .trim()
    .email(intl.formatMessage(messages.EMAIL_MUST_BE_VALID))
    .max(241, intl.formatMessage(messages.MAX_241_CHARACTERS))

  const requiredEmail = yup
    .string()
    .trim()
    .test('validateEmail', 'Invalid Email', (value) => isValidEmail(value))
    .required(
      intl.formatMessage({ id: 'REQUIRED', defaultMessage: 'Required' })
    )
    .max(241, intl.formatMessage(messages.MAX_241_CHARACTERS))

  const requiredUserName = yup
    .string()
    .trim()
    .required(intl.formatMessage(messages.USERNAME_REQUIRED))

  // allows a comma-separated list of valid emails
  const ccEmail = yup
    .string()
    .trim()
    .matches(
      /^$|(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(?:,\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/,
      intl.formatMessage(messages.EMAIL_MUST_BE_VALID)
    )
    .max(241, intl.formatMessage(messages.MAX_241_CHARACTERS))

  const required = yup
    .string()
    .trim()
    .required(
      intl.formatMessage({ id: 'REQUIRED', defaultMessage: 'Required' })
    )

  const requiredPhone = yup
    .string()
    .trim()
    .max(20, intl.formatMessage(messages.MAX_20_CHARACTERS))
    .matches(/^[0-9()-]*$/, intl.formatMessage(messages.NUMERIC_PHONE))
    .required(
      intl.formatMessage({ id: 'REQUIRED', defaultMessage: 'Required' })
    )

  const requiredPhoneWithSpaces = yup
    .string()
    .trim()
    .max(20, intl.formatMessage(messages.MAX_20_CHARACTERS))
    .test(
      'validatePhoneWithSpaces',
      intl.formatMessage(messages.PHONE_FAX_NO_HELP_MSG),
      (value) => isValidPhoneWithSpaces(value)
    )
    .required(
      intl.formatMessage({ id: 'REQUIRED', defaultMessage: 'Required' })
    )

  const phone = yup
    .string()
    .max(20, intl.formatMessage(messages.MAX_20_CHARACTERS))
    .test(
      'validatePhoneWithSpaces',
      intl.formatMessage(messages.PHONE_FAX_NO_HELP_MSG),
      (value) => isValidPhoneWithSpaces(value)
    )

  const phoneWithSpaces = yup
    .string()
    .max(20, intl.formatMessage(messages.MAX_20_CHARACTERS))
    .test(
      'validatePhoneWithSpaces',
      intl.formatMessage(messages.PHONE_FAX_NO_HELP_MSG),
      (value) => isValidPhoneWithSpaces(value)
    )

  const extension = yup
    .string()
    .max(10, intl.formatMessage(messages.MAX_10_CHARACTERS))
    .matches(/^[0-9()-]*$/, intl.formatMessage(messages.NUMERIC_PHONE))
    .test(
      'atLeastTwoNumbers',
      intl.formatMessage(messages.MIN_2_NUMBERS),
      (v) => v?.length !== 1
    )

  const extensionWithSpaces = yup
    .string()
    .max(10, intl.formatMessage(messages.MAX_10_CHARACTERS))
    .matches(
      /^[0-9()-\s]*$/,
      intl.formatMessage(messages.NUMERIC_PHONE_WITH_SPACE)
    )
    .test(
      'atLeastTwoNumbers',
      intl.formatMessage(messages.MIN_2_NUMBERS),
      (v) => v?.length !== 1
    )

  const fax = yup
    .string()
    .max(20, intl.formatMessage(messages.MAX_20_CHARACTERS))
    .matches(/^[0-9()-]*$/, intl.formatMessage(messages.NUMERIC_FAX))

  const faxWithSpaces = yup
    .string()
    .max(20, intl.formatMessage(messages.MAX_20_CHARACTERS))
    .test(
      'validatePhoneWithSpaces',
      intl.formatMessage(messages.PHONE_FAX_NO_HELP_MSG),
      (value) => isValidPhoneWithSpaces(value)
    )

  const mobile = yup
    .string()
    .max(16, intl.formatMessage(messages.MAX_16_CHARACTERS))
    .matches(/^[0-9()-]*$/, intl.formatMessage(messages.NUMERIC_MOBILE))

  const mobileChinaUser = yup
    .string()
    .test(
      'validChinaMobileNumber',
      intl.formatMessage(messages.MOBILE_FORMAT_INVALID),
      (value) => isValidChinaMobile(value)
    )

  const mobileWithSpaces = yup
    .string()
    .max(16, intl.formatMessage(messages.MAX_16_CHARACTERS))
    .test(
      'validatePhoneWithSpaces',
      intl.formatMessage(messages.PHONE_FAX_NO_HELP_MSG),
      (value) => isValidPhoneWithSpaces(value)
    )

  const numeric = yup
    .string()
    .matches(/^[0-9]*$/, intl.formatMessage(messages.NUMBERS_ONLY))

  const alphanumeric = yup
    .string()
    .matches(/^[0-9a-zA-Z]*$/, intl.formatMessage(messages.ALPHANUMERIC_ONLY))

  const floatToTwoDecimalPlaces = yup
    .string()
    .matches(
      /^[0-9]+(\.[0-9]{1,2})?$/,
      intl.formatMessage(messages.PLEASE_ENTER_VALID_AMOUNT)
    )
    .test(
      'equalToOrGreaterThanOne',
      intl.formatMessage(messages.PLEASE_ENTER_VALID_AMOUNT),
      (n) => Number(n) >= 1
    )

  const minLength = (min: number) =>
    yup
      .string()
      .min(min, intl.formatMessage(messages.MIN_N_CHARACTERS, { min }))

  const maxLength = (max: number) =>
    yup
      .string()
      .max(max, intl.formatMessage(messages.MAX_N_CHARACTERS, { max }))

  const futureDateRequired = yup
    .date()
    .max(
      new Date(Date.UTC(9999, 1)),
      intl.formatMessage(messages.EXP_YEAR_MUST_BE_4_DIGITS)
    )
    .min(new Date(), intl.formatMessage(messages.EXP_DATE_CANNOT_BE_IN_PAST))
    .required(
      intl.formatMessage({ id: 'REQUIRED', defaultMessage: 'Required' })
    )

  const hasNoEmojis = yup.string().test(
    'emojiTest',
    intl.formatMessage({
      id: 'EMOJI_NOT_SUPPORTED',
      defaultMessage: ' Emojis are not supported',
    }),
    (value) => {
      return !hasEmojis(value)
    }
  )

  return {
    email: validateField(email),
    ccEmail: validateField(ccEmail),
    requiredEmail: validateField(requiredEmail),
    requiredUserName: validateField(requiredUserName),
    requiredPhone: validateField(requiredPhone),
    requiredPhoneWithSpaces: validateField(requiredPhoneWithSpaces),
    required: validateField(required),
    phone: validateField(phone),
    phoneWithSpaces: validateField(phoneWithSpaces),
    extension: validateField(extension),
    extensionWithSpaces: validateField(extensionWithSpaces),
    fax: validateField(fax),
    faxWithSpaces: validateField(faxWithSpaces),
    mobile: validateField(mobile),
    mobileWithSpaces: validateField(mobileWithSpaces),
    mobileChinaUser: validateField(mobileChinaUser),
    numeric: validateField(numeric),
    alphanumeric: validateField(alphanumeric),
    floatToTwoDecimalPlaces: validateField(floatToTwoDecimalPlaces),
    minLength: (min: number) => validateField(minLength(min)),
    maxLength: (max: number) => validateField(maxLength(max)),
    futureDateRequired: validateField(futureDateRequired),
    hasNoEmojis: validateField(hasNoEmojis),
    rawYupSchema: {
      email,
      ccEmail,
      requiredEmail,
      requiredUserName,
      requiredPhone,
      requiredPhoneWithSpaces,
      required,
      phone,
      phoneWithSpaces,
      extension,
      extensionWithSpaces,
      fax,
      faxWithSpaces,
      mobile,
      mobileWithSpaces,
      mobileChinaUser,
      numeric,
      alphanumeric,
      floatToTwoDecimalPlaces,
      minLength,
      maxLength,
      futureDateRequired,
      hasNoEmojis,
    },
  }
}
