import React, { CSSProperties } from 'react'
import clsx from 'clsx'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Checkbox } from '@material-ui/core'
import { CheckboxProps } from '@material-ui/core/Checkbox'
import CheckMarkIcon from '@icons/CheckMarkIcon'
import { SwitchBaseProps } from '@material-ui/core/internal/SwitchBase'

interface CommonCheckboxProps
  extends Omit<
    CheckboxProps,
    'onBlur' | 'size' | 'color' | 'indeterminate' | 'indeterminateIcon'
  > {
  error?: string
  name?: string
  id?: string
  testId?: string
  value?: string
  checked?: SwitchBaseProps['checked']
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  style?: CSSProperties
  color?: 'primary' | 'secondary' // Updated type for icon colors
  disabled?: boolean
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
  // default override to support only two checkbox sizes
  size?: 'default' | 'large'
}

const useStyles = makeStyles<Theme, { size: 'default' | 'large' }>(
  (theme: Theme) => ({
    checkbox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: ({ size }) =>
        size === 'large'
          ? theme.typography.pxToRem(24)
          : theme.typography.pxToRem(20),
      width: ({ size }) =>
        size === 'large'
          ? theme.typography.pxToRem(24)
          : theme.typography.pxToRem(20),
      fontSize: ({ size }) =>
        size === 'large'
          ? theme.typography.pxToRem(16)
          : theme.typography.pxToRem(12),
      border: `solid 1px ${theme.palette.grey[500]}`,
      borderRadius: theme.typography.pxToRem(3),
      transition: 'all .3s',
      'input:hover ~ &': {
        boxShadow: `0px 0px 0px 3px ${theme.palette.primary.light}`,
      },
    },
    checkboxFocusVisible: {
      '& $checkbox': {
        boxShadow: `0 0 6px 0 ${theme.palette.primary.main}`,
        border: `solid 1px ${theme.palette.primary.main}`,
      },
    },
    checkboxError: {
      border: `solid 2px ${theme.palette.error.main}`,
      '& svg': {
        color: theme.palette.error.main,
      },
    },
    checkmarkIcon: {
      fontSize: ({ size }) =>
        size === 'large'
          ? theme.typography.pxToRem(16)
          : theme.typography.pxToRem(12),
    },
    inputDisabled: {
      borderColor: theme.palette.grey[400],
      backgroundColor: theme.palette.grey[400],
      'input:hover ~ &': {
        boxShadow: 'none',
      },
    },
  })
)

const CommonCheckbox: React.FC<CommonCheckboxProps> = ({
  error,
  size = 'default',
  color = 'primary',
  name,
  id,
  testId = 'common-checkbox',
  value,
  disabled,
  checked,
  onChange,
  onBlur,
  inputProps,
  className,
  ...otherProps
}) => {
  const classes = useStyles({ size })

  return (
    <Checkbox
      data-testid={testId}
      disabled={disabled}
      id={id}
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
      //MUI's type is inaccurate, as it expects an HTMLButtonElement, when it should expect HTMLInputElement
      onBlur={onBlur as React.FocusEventHandler<HTMLButtonElement>}
      focusVisibleClassName={classes.checkboxFocusVisible}
      inputProps={{
        'aria-describedby': error ? `${name}-help-text` : undefined,
        'aria-disabled': disabled || undefined,
        ...inputProps,
      }}
      icon={
        <div
          className={clsx(
            classes.checkbox,
            {
              [classes.checkboxError]: error,
              [classes.inputDisabled]: disabled,
            },
            className
          )}
        />
      }
      checkedIcon={
        <div
          className={clsx(
            classes.checkbox,
            {
              [classes.checkboxError]: error,
              [classes.inputDisabled]: disabled,
            },
            className
          )}
        >
          <CheckMarkIcon
            data-testid="checkmark-icon"
            className={classes.checkmarkIcon}
            fontSize="inherit"
            color={disabled ? 'disabled' : color}
          />
        </div>
      }
      {...otherProps}
    />
  )
}

export default CommonCheckbox
