import React from 'react'
import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import { Theme, Grid, Typography } from '@material-ui/core'
import messages from '@utils/messages'
import { HandleMarkup } from '@src/components/HandleMarkup'
import ResponsiveModal from '@src/components/ResponsiveModal'
import {
  CartAdditionalInfo,
  OrderAdditionalInfo,
} from '@src/types/graphql-types'
import { useResponsiveGridSpacing } from '@utils/useResponsiveSizes'

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    padding: theme.spacing(6, 8, 8, 8),
  },
  description: {
    marginBottom: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  column: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
    },
  },
}))

export interface AdditionalInfoProps {
  open: boolean
  additionalInfo: OrderAdditionalInfo | CartAdditionalInfo
  productName?: string
  productNumber?: string
  description?: Maybe<string>
  handleClose: () => void
  packageType?: string | null
  packageSize?: string | null
}

const AdditionalInfoColumn = ({
  labelValue,
  columnValue,
  isDescription = false,
}) => {
  const classes = useStyles()
  const getSpacing = useResponsiveGridSpacing()

  return (
    <Grid
      container
      spacing={getSpacing({ xs: 0, md: 3 })}
      className={clsx({ [classes.column]: !isDescription })}
    >
      <Grid item xs={12} md={5}>
        <Typography component="h4" variant="body2">
          <b>
            <FormattedMessage {...messages[labelValue]} />
          </b>
        </Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <Typography component="p" variant="body2">
          <HandleMarkup value={columnValue} />
        </Typography>
      </Grid>
    </Grid>
  )
}

const AdditionalInfoDialog: React.FC<AdditionalInfoProps> = ({
  open,
  additionalInfo,
  productName,
  productNumber,
  description,
  handleClose,
  packageType,
  packageSize,
}) => {
  const classes = useStyles()
  const hasDescriptionDivider =
    additionalInfo?.carrierRestriction ||
    additionalInfo?.tariff ||
    additionalInfo?.unNumber ||
    additionalInfo?.casNumber ||
    additionalInfo?.species ||
    additionalInfo?.jfcCode ||
    additionalInfo?.pdcCode

  return (
    <ResponsiveModal
      renderTitle={() => (
        <Typography variant="h2" component="h2">
          <FormattedMessage {...messages.PRODUCT_INFO_TITLE} />
        </Typography>
      )}
      open={open}
      onClose={handleClose}
    >
      <div className={classes.body}>
        <div className={clsx({ [classes.description]: hasDescriptionDivider })}>
          {productNumber && (
            <AdditionalInfoColumn
              labelValue="MATERIAL_NUMBER_SKU"
              columnValue={productNumber}
            />
          )}
          {description && (
            <AdditionalInfoColumn
              labelValue="ADDITIONAL_INFO_DESCRIPTION"
              columnValue={productName + ', ' + description}
              isDescription
            />
          )}
        </div>

        {additionalInfo?.carrierRestriction && (
          <AdditionalInfoColumn
            labelValue="ADDITIONAL_INFO_CARRIER_RESTRICTION"
            columnValue={additionalInfo.carrierRestriction}
          />
        )}

        {additionalInfo?.tariff && (
          <AdditionalInfoColumn
            labelValue="ADDITIONAL_INFO_FTCC"
            columnValue={additionalInfo.tariff}
          />
        )}

        {additionalInfo?.unNumber && (
          <AdditionalInfoColumn
            labelValue="ADDITIONAL_INFO_UNTN"
            columnValue={additionalInfo.unNumber}
          />
        )}

        {additionalInfo?.casNumber && (
          <AdditionalInfoColumn
            labelValue="ADDITIONAL_INFO_CAS"
            columnValue={additionalInfo.casNumber}
          />
        )}

        {additionalInfo?.species && (
          <AdditionalInfoColumn
            labelValue="ADDITIONAL_INFO_SPECIES"
            columnValue={additionalInfo.species}
          />
        )}

        {additionalInfo?.jfcCode && (
          <AdditionalInfoColumn
            labelValue="ADDITIONAL_INFO_JFC"
            columnValue={additionalInfo.jfcCode}
          />
        )}

        {additionalInfo?.pdcCode && (
          <AdditionalInfoColumn
            labelValue="ADDITIONAL_INFO_PDSC"
            columnValue={additionalInfo.pdcCode}
          />
        )}

        {packageType?.length && packageSize?.length ? (
          <AdditionalInfoColumn
            labelValue="ADDITIONAL_INFO_PACKAGING_INFO"
            columnValue={`${packageSize} in ${packageType}`}
          />
        ) : null}
      </div>
    </ResponsiveModal>
  )
}

export default AdditionalInfoDialog
