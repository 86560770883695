import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import Box, { BoxProps } from '@material-ui/core/Box'

const useStyles = makeStyles((theme: Theme) => {
  return {
    loading: {
      borderRadius: theme.shape.borderRadius / 2,
      background: `linear-gradient(to right, ${theme.palette.grey[200]}, ${theme.palette.grey[200]} 25%, #f3f3f3, ${theme.palette.grey[200]} 75%, ${theme.palette.grey[200]})`,
      backgroundSize: '200% 200%',
      backgroundPositionX: '100%',
      animation: '$animation 2s ease-out infinite',
      animationDirection: 'normal',
      animationDelay: '1s',
      [theme.breakpoints.up('sm')]: {
        borderRadius: theme.shape.borderRadius,
      },
    },
    '@keyframes animation': {
      '0%': { backgroundPositionX: '100%' },
      '50%': { backgroundPositionX: '0%' },
      '100%': { backgroundPositionX: '100%' },
    },
  }
})

interface LoadingBlockProps extends BoxProps {
  className?: string
  style?: React.CSSProperties
}

const LoadingBlock: React.FC<LoadingBlockProps> = ({
  className,
  style,
  ...boxProps
}) => {
  const classes = useStyles()
  return (
    <Box
      className={clsx(classes.loading, className)}
      style={style}
      {...boxProps}
    />
  )
}

export default LoadingBlock
